<script setup lang="ts">
import { ExclamationIcon, QuestionMarkCircleIcon } from '@heroicons/vue/outline';
import type { PropType } from 'vue';
import ProfileHandle from '~/components/ProfileHandle.vue';
import { formatXchPrice } from '~/utilities/chia-display-utils';

const props = defineProps({
  mint: { type: Object as PropType<any>, required: true },
});
const { mint } = toRefs(props);
const requestedEntries = computed(() => Object.entries(mint.value?.requested || {}));

const name = computed(() => mint.value?.mint_name || mint.value?.name);
const thumbnailUri = computed(() => mint.value?.mint_thumbnail_uri || mint.value?.thumbnail_uri);
</script>

<template>
  <div
    class="shadow-card hover:shadow-card-hover group relative flex flex-col overflow-hidden rounded-lg bg-white transition duration-300 ease-in-out dark:bg-neutral-800"
  >
    <NuxtLink :to="mint && `/mints/${mint.id}`" class="aspect-h-1 aspect-w-1 relative overflow-hidden">
      <div v-if="!mint" class="flex items-center justify-center">
        <div class="h-full w-full animate-pulse bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <div v-else-if="mint.creator?.verification_state === 2" class="flex items-center justify-center">
        <ExclamationIcon class="h-1/2 w-1/2 text-red-600" />
      </div>
      <img
        v-else-if="thumbnailUri"
        :alt="`${name} image`"
        :src="thumbnailUri"
        loading="lazy"
        class="h-full w-full object-cover object-center transition duration-300 ease-out betterhover:group-hover:scale-110"
      />
      <div v-else class="flex items-center justify-center">
        <QuestionMarkCircleIcon class="h-1/2 w-1/2 text-neutral-400 dark:text-neutral-500" />
      </div>
      <div class="absolute inset-0 z-20 flex h-full w-full flex-col items-center justify-start bg-transparent">
        <div class="flex-1"></div>
        <div class="flex w-full px-4 py-4 opacity-0"></div>
      </div>
    </NuxtLink>
    <div class="flex flex-1 flex-col justify-between gap-2 p-4">
      <div v-if="!mint">
        <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <template v-else>
        <div class="flex items-center gap-1">
          <span class="truncate text-lg font-medium text-black dark:text-white">{{ name }}</span>
        </div>
        <div class="flex flex-1 flex-row items-start justify-between gap-2">
          <ProfileHandle :profile="mint.creator" />
        </div>
        <div
          class="mt-2 flex flex-1 flex-row items-start justify-between gap-2 border-t-2 border-neutral-100 pt-4 dark:border-neutral-700"
        >
          <div class="flex flex-col items-start">
            <span class="text-xs font-semibold text-neutral-500 dark:text-neutral-400">Price</span
            ><span class="text-sm font-semibold text-black dark:text-white"
              ><template v-for="(requestedEntry, index) in requestedEntries" :key="index">
                <span v-if="requestedEntry[0] === 'xch'" class="text-sm font-semibold text-black dark:text-white">
                  {{ formatXchPrice(requestedEntry[1]) }}
                </span>
                <NuxtLink
                  v-else
                  target="_blank"
                  :to="`https://dexie.space/assets/${requestedEntry[0]}`"
                  class="inline-flex items-center gap-1 text-sm font-semibold text-black dark:text-white"
                >
                  <img class="h-6 w-6 rounded-full" :src="`https://icons.dexie.space/${requestedEntry[0]}.webp`" />{{
                    requestedEntry[1]
                  }}
                </NuxtLink>
                {{ index !== requestedEntries.length - 1 ? ' + ' : '' }}
              </template></span
            >
          </div>
          <div class="flex flex-col items-start">
            <span class="text-xs font-semibold text-neutral-500 dark:text-neutral-400">Unminted NFTs</span
            ><span class="text-sm font-semibold text-black dark:text-white">{{ mint.unminted_nft_count }} left</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
